import React from 'react'
import SVG from './SVG'

const HowItWorksModal = ({ handleDismiss }) => {
  return (
    <section>
      <button onClick={handleDismiss} className="close" ><SVG name="close" label="Close" size="md" /></button>
      <h3>picking up orders is easy and stress-free!</h3>
      <ul>
        <li>When you checkout, you'll select a date and time to pick up your order.</li>
        <li>
          Orders can be canceled on your order's detail page up to 30 minutes after placing a same day pickup order or
          before 8:00am on your scheduled pickup day.
        </li>
        <li>We'll send you an email or optional SMS when your order is ready.</li>
        <li>
          When your order is ready we'll let you know which in-store pickup bins to find your items. Just grab your
          order and enjoy!
        </li>
        <li>If you need assistance when you're at the store, see a pOpshelf® team member.</li>
      </ul>
      <button className="btn__secondary" onClick={handleDismiss}>
        CLOSE
      </button>
    </section>
  )
}

export default HowItWorksModal
