import React, { useContext, useState, useEffect, useRef } from 'react'
import Context from '../context'
import lottie from 'lottie-web'
import animation from '../bubbles.json'
import MainCarousel from '../components/MainCarousel'
import StarRating from './StarRating'
import Link from 'next/link'
import getConfig from 'next/config'
import ShopSwitchPicker from '../components/ShopSwitchPicker'
import VariantPicker from '../components/VariantPicker'
import PDPFulfillment from './PDPFulfillment'
import { useAuthAPI } from '../hooks/api.hooks'
import SVG from './SVG'

const { publicRuntimeConfig } = getConfig()

const QuickView = (props) => {
  const { state, dispatch } = useContext(Context)
  const [optionsSize, setOptionsSize] = useState(-1)
  const [selectedProduct, setSelectedProduct] = useState<any>({})
  const [selectedOptions, setSelectedOptions] = useState<any>({})
  const [productDetail, setProductDetail] = useState([])
  const authAPICall = useAuthAPI()
  const animationContainer = useRef()
  const handleDismiss = props.handleDismiss
  const data = props.data

  useEffect(() => {
    if (Object.keys(selectedProduct).length) {
      lottie.destroy('bubbles')
      try {
        window.BrTrk.getTracker().logEvent('product', 'quickview', { prod_id: `${selectedProduct.id}`, prod_name: `${selectedProduct.product_name}`, sku: `${selectedProduct.sku}` })
      } catch (e) {
        console.log(e)
      }
    } else {
      lottie.loadAnimation({
        container: animationContainer.current,
        animationData: animation,
        loop: -1,
        name: 'bubbles'
      })
    }
  }, [selectedProduct])

  useEffect(() => {
    if (Object.keys(selectedOptions).length === optionsSize) {
      const foundSelectedProd = productDetail.find(prod => {
        let found = true
        for (const attr in selectedOptions) {
          if (`${prod[attr]}` !== `${selectedOptions[attr]}`) {
            found = false
            break
          }
        }
        return found
      })
      foundSelectedProd ? setSelectedProduct(foundSelectedProd) : setSelectedProduct({ ...selectedProduct, qtyOnHand: 0 })
    }
  }, [selectedOptions, optionsSize])

  useEffect(() => {
    if (productDetail.length) {
      const tempSelectedProduct = productDetail.find(item => item.upc === selectedProduct?.upc)
      tempSelectedProduct ? setSelectedProduct(tempSelectedProduct) : setSelectedProduct(productDetail[0])
    }
  }, [productDetail])

  useEffect(() => {
    const getProduct = async () => {
      authAPICall(
        'productinfo',
        'product',
        'GET',
        {
          slug: data.slug,
          store: state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE,
          popkey: publicRuntimeConfig.POP_KEY
        },
        {},
        { deviceid: state.deviceId || '' }
      ).then((data) => {
        setProductDetail(data)
      })
    }
    getProduct()
  }, [state.product_preview_data])

  const getDropDownValues = (value, productDetail) => {
    const uniqueLetters = [...new Set(productDetail.map(x => x[value]))]
    const letteroptions = []
    const altOptions = Object.keys(selectedOptions)
    uniqueLetters.forEach(element => {
      const currentAltSelection = altOptions.filter(x => x !== value)
      if (productDetail.some(x => x.qtyOnHand > 0 && x[value] === element && (altOptions.length < 2 || !altOptions.includes(value) || (altOptions.includes(value) && currentAltSelection.length > 0 && x[currentAltSelection[0]] === selectedOptions[currentAltSelection[0]])))) {
        letteroptions.push({ value: element, number: element })
      } else {
        letteroptions.push({ value: element, number: element + ' - unavailable' })
      }
    })
    return letteroptions.sort((a, b) => (a.number > b.number) ? 1 : -1)
  }

  useEffect(() => {
    if (productDetail.length) {
      const initialProduct = productDetail.find(e => e.slug === (selectedProduct || data).slug)
      const obj: any = {}
      for (const dropdown in initialProduct?.dropdown_attr) {
        switch (dropdown) {
          case 'Metal':
            obj.metal = initialProduct.metal
            break
          case 'Plating':
            obj.plating = initialProduct.plating
            break
          case 'Pattern':
            obj.pattern = initialProduct.pattern
            break
          case 'Finish':
            obj.finish = initialProduct.finish
            break
          case 'Scent':
            obj.scent = initialProduct.scent
            break
          case 'Color':
            obj.color = initialProduct.color
            break
          case 'Diaper Size':
            obj.diaper_size = initialProduct.diaper_size
            break
          case 'Baby Clothing Size':
            obj.baby_clothing_size = initialProduct.baby_clothing_size
            break
          case 'Count':
            obj.count = initialProduct.count
            break
          case 'Size':
            obj.size = initialProduct.size
            break
          case 'Letter':
            obj.letter = initialProduct.letter
            break
          case 'Number':
            obj.number = initialProduct.number
            break
        }
      }
      setSelectedOptions({ ...obj })
    }
  }, [productDetail, selectedProduct])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedItems = state.recentlyViewed
      const index = storedItems.findIndex(
        (e) => e.sku === data?.digital_sku
      )
      if (index !== -1) {
        const del = storedItems.splice(index, 1)
        del[0].price = data?.price
        del[0].store = state.cart.store?.storenumber
        storedItems.unshift(del[0])
      } else {
        storedItems.unshift({
          title: data?.product_name,
          time: new Date(),
          sku: data?.digital_sku,
          upc: data?.upc,
          image: data?.image_urls
            ? data?.image_urls.split(',')[0]
            : null,
          slug: data?.slug,
          avg_rating: data?.avg_rating,
          price: data?.price,
          store: state.cart.store?.storenumber
        })

        if (storedItems.length > 10) {
          storedItems.length = 10
        }
      }
      dispatch({ type: 'SET_RECENTLY_VIEWED', payload: storedItems })
    }
  }, [])

  const renderOptions = () => {
    const arr = []
    if (productDetail[0].dropdown_attr.Metal) {
      const options = [...new Set(productDetail.map(item => (
        { number: item.qtyOnHand === 0 ? item.metal + ' - unavailable' : item.metal })))]
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Metal'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, metal: choice })
          }
          value={selectedOptions.metal}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Plating) {
      const options = getDropDownValues('plating', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Plating'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, plating: choice })
          }
          value={selectedOptions.plating}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Pattern) {
      const options = getDropDownValues('pattern', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Pattern'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, pattern: choice })
          }
          value={selectedOptions.pattern}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Finish) {
      const options = getDropDownValues('finish', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Finish'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, finish: choice })
          }
          value={selectedOptions.finish}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Scent) {
      const options = getDropDownValues('scent', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Scent'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, scent: choice })
          }
          value={selectedOptions.scent}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Color) {
      const options = [...new Set(productDetail.map(item => item.color))]
      const altOption = Object.keys(selectedOptions).filter(option => option !== 'color')[0]
      const colorImages = [...new Set(productDetail.map(item => {
        const returnImage = { color: item.color, images: item.image_urls, isAvailable: item.qtyOnHand > 0 }
        if (altOption) {
          returnImage[altOption] = item[altOption]
        }
        return returnImage
      }))]
      if (options.length > 1) {
        arr.push(<ShopSwitchPicker
          quickView={true}
          name='Color'
          data={options}
          product={selectedProduct}
          images={colorImages}
          altOption={altOption}
          onSelect={(choice) => setSelectedOptions({ ...selectedOptions, color: choice })}
        />)
      }
    }
    if (productDetail[0].dropdown_attr['Diaper Size']) {
      const options = getDropDownValues('diaper_size', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Diaper Size'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, diaper_size: choice })
          }
          value={selectedOptions.diaper_size}
        />)
      }
    }
    if (productDetail[0].dropdown_attr['Baby Clothing Size']) {
      const options = getDropDownValues('baby_clothing_size', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Baby Clothing Size'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, baby_clothing_size: choice })
          }
          value={selectedOptions.baby_clothing_size}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Count) {
      const options = getDropDownValues('count', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Count'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, count: choice })
          }
          value={selectedOptions.count}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Size) {
      const options = getDropDownValues('size', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Size'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, size: choice })
          }
          value={selectedOptions.size}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Letter) {
      const options = getDropDownValues('letter', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Letter'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, letter: choice })
          }
          value={selectedOptions.letter}
        />)
      }
    }
    if (productDetail[0].dropdown_attr.Number || productDetail[0].dropdown_attr.Number === 0) {
      const options = getDropDownValues('number', productDetail)
      if (options.length > 1) {
        arr.push(<VariantPicker
          quickView={true}
          name='Number'
          data={options}
          onSelect={(choice) =>
            setSelectedOptions({ ...selectedOptions, number: choice })
          }
          value={selectedOptions.number}
        />)
      }
    }
    optionsSize === -1 && setOptionsSize(arr.length)
    return arr
  }

  const renderPrice = () => {
    return (
      <p className="quickview__price">
        <strong className="pop-amount">
          ${selectedProduct && selectedProduct.discount_price ? parseFloat(selectedProduct.discount_price).toFixed(2) : parseFloat(selectedProduct.price).toFixed(2)}
          <span className="pop-reg-price">
            {selectedProduct && selectedProduct.discount_price && <b>reg</b>} {selectedProduct && selectedProduct.discount_price && `$${parseFloat(selectedProduct.price).toFixed(2)}`}
          </span>
        </strong>
      </p>
    )
  }

  return (
    <div className="quickview__wrapper">
      {typeof window !== 'undefined' && (
        <div>
          <div className="quickview__close">
            <div className="quickview__close-icon " onClick={() => handleDismiss()}><SVG name="close" label="Close" size="base" /></div>
          </div>
          {!!Object.keys(selectedProduct).length && <div className="quickview__modal">
            <div className="quickview__modal-images">
                <MainCarousel imageUrls={selectedProduct.image_urls} />
            </div>

            <div className="quickview__modal-details">
              <p className="quickview__details-title">
                {selectedProduct.digital_product_name}
              </p>
              {<div className="quickview__details-rating">
                <StarRating percent={selectedProduct.avg_rating} id={selectedProduct.upc} />
                <span className="quickview__details-ratingcount">({selectedProduct.rating_count || 0})</span>
              </div>}

              {renderPrice()}

              <div className="quickview__details-options">
                {!!productDetail.length && renderOptions()}
              </div>

              {!selectedProduct.price &&
                <div className="row product-cta pdp__instore-text">
                  <p className='pdp__instore-label'>This item is not available at this store</p>
                </div>
              }

              <PDPFulfillment selectedProduct={selectedProduct} closeQuickViewModal={handleDismiss} />

              <Link href='/p/[slug]' as={`/p/${selectedProduct.slug}`}>
                <a>
                  <button className="btn__secondary btn__view-full-details" onClick={() => handleDismiss()}><label className="button-text">VIEW FULL DETAILS</label></button>
                </a>
              </Link>
            </div>
          </div>}
          {Object.keys(selectedProduct).length === 0 &&
          <div className="quickview-bubbles-animation">
            <div
              className="animation-container"
              style={{
                position: 'fixed',
                height: '100%',
                width: '100%',
                backgroundColor: 'white',
                top: 0,
                left: 0
              }}
              ref={animationContainer}
            />
            </div>
          }
        </div>
      )}
    </div>
  )
}

export default QuickView
