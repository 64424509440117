import { useState } from 'react'
import Image from 'next/image'
import { thumbLoader } from '../utils/custom-image-loader'

const ShopSwitchPickerItem = ({ data }) => {
  const { color, product, images, altOption, onSelect } = data
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const image = images.find((image) => {
    if (!image) {
      return undefined
    }
    return image.color === color && image[altOption] === product[altOption]
  })
  const [imageSrc, setImageSrc] = useState(image?.images.split(',')[0] || brokenImage)

  if (!image) {
    return <div data-testid="shop-switch-picker-item-empty"></div>
  }

  if (!image.isAvailable) {
    return (
      <div data-testid="shop-switch-picker-item-oos" className="image-picker__oos-wrapper" onClick={() => onSelect(color)}>
        <label className="image-picker__oos-line"></label>
        <div className="switch-image-size oos">
          <Image
            alt={color}
            src={imageSrc}
            height={60}
            width={60}
            loader={thumbLoader}
            onError={() => setImageSrc(brokenImage)}
          />
        </div>
      </div>
    )
  } else {
    return (
      <button
        id="switch-image-button"
        onClick={() => onSelect(color)}
        className={`switch-image-size ${product.color === color && 'switch-image-size__selected'}`}
      >
        <Image
          alt={color}
          src={imageSrc}
          height={60}
          width={60}
          loader={thumbLoader}
          onError={() => setImageSrc(brokenImage)}
        />
      </button>
    )
  }
}

export default ShopSwitchPickerItem
