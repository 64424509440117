import SVG from './SVG'

const RemoveItemModal = ({ hide, product, removeItem }) => {
  return (
      <section>
       <SVG name="circle-flag" label="circle-flag" size="xxl" />
        <p className="mt-3">Are you sure you want to remove that product?</p>
        <button id="remove-cart-item" className="btn__primary" data-name={product.description} data-upc={product.upc}
          onClick={(e) => {
            e.preventDefault()
            removeItem(0)
            hide()
          }}
        >
          REMOVE ITEM
        </button>
        <button className="btn__secondary" onClick={hide}>
          BACK
        </button>
      </section>
  )
}

export default RemoveItemModal
