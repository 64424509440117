import ShopSwitchPickerItem from './ShopSwitchPickerItem'

const ShopSwitchPicker = ({ name, data, images, onSelect, product, altOption, quickView = false }) => {
  const renderColorSwitch = () => {
    return data.map((color) => (
      <ShopSwitchPickerItem key={color} data={{ color, product, images, altOption, onSelect }} />
    ))
  }

  return (
    <div className="">
      <div data-testid="shop-switch-picker-color-picker" className={`shop-switch-picker__color-picker ${quickView && 'quickview__options-colortitle'}`}>
        <p className="shop-switch-picker__color-picker__label">{name}:</p>
        <p id="selected-color" className="shop-switch-picker__color-picker__value">
          {product.color}
        </p>
      </div>
      <div className="col-12 px-3 row">{renderColorSwitch()}</div>
    </div>
  )
}

export default ShopSwitchPicker
