import React, { useContext } from 'react'
import { useIonModal } from '@ionic/react'
import Context from '../context'
import AddToCart from './AddToCart'
import StoreSearch from '../components/StoreSearch'
import HowItWorksModal from './HowItWorksModal'
import SVG from './SVG'

const PDPFulfillment = ({ selectedProduct, closeQuickViewModal }) => {
  const { state } = useContext(Context)

  const handleDismiss = () => {
    dismiss()
  }
  const handleDismissHowItWorks = () => {
    dismissHowItWorks()
  }
  const [present, dismiss] = useIonModal(StoreSearch, {
    handleDismiss,
    sku: selectedProduct.sku
  })
  const [presentHowItWorks, dismissHowItWorks] = useIonModal(HowItWorksModal, {
    handleDismiss: handleDismissHowItWorks
  })

  const finalPrice = parseFloat(selectedProduct?.final_price || 0).toFixed(2)

  const determineInventoryLevel = () => {
    if (!selectedProduct.qtyOnHand) {
      if (selectedProduct.price && finalPrice !== '0.01') {
        return <span className="pdp-fulfillment__stock-oos">Out of stock at</span>
      } else {
        return <span className="pdp-fulfillment__stock-oos">Item not sold at</span>
      }
    } else {
      if (finalPrice === '0.01') {
        return <span className="pdp-fulfillment__stock-oos">Item not sold at</span>
      } else {
        return <span>{selectedProduct.qtyOnHand} in stock at</span>
      }
    }
  }

  const getQuantity = () => {
    return state.cart?.products?.find(product => +selectedProduct.upc === +product.upc)?.quantity || 0
  }

  return (
    <div className="pdp-fulfillment">
      <div className="pdp-fulfillment__option">
        <div className="pdp-fulfillment__option-header">
          <div className='pdp-fulfillment__option-header-pickup'>
            <div className='pdp-fulfillment__option-header-pickup-image'>
            <SVG name="bag" label="bag" size="md" />
            </div>
            <p>{selectedProduct.in_store_only ? 'Exclusively available in store' : 'In-store pickup'}</p>
          </div>
          {!selectedProduct.in_store_only && !!selectedProduct.price &&
            <button onClick={ () => {
              presentHowItWorks({ cssClass: 'modal--fullscreen' })
              closeQuickViewModal && closeQuickViewModal()
            }}>How pickup works
            </button>}
        </div>
        <div className='tooltip' id='tooltip'></div>
        <div>
        </div>
        <p className={`pdp-fulfillment__option-stock ${typeof selectedProduct.qtyOnHand === 'undefined' ? 'pdp-fulfillment__option-hidden' : ''}`}>{determineInventoryLevel()}</p>
        <div className="pdp-fulfillment__option-footer">
          <button className="pdp-fulfillment__option-footer-address"
            onClick={() => {
              present({ cssClass: 'modal--fullscreen' })
              closeQuickViewModal && closeQuickViewModal()
            }}>
            <address>
              <p>{state.cart.store?.address}</p>
              <p>{state.cart.store?.city}, {state.cart.store?.state}</p>
            </address>
          </button>
          {
            !selectedProduct.in_store_only &&
            !!selectedProduct.price &&
            selectedProduct.qtyOnHand > 0 &&
            finalPrice !== '0.01' &&
              <div className='add-to-cart__setbutton'>
                <AddToCart product={selectedProduct} cartQuantity={getQuantity()} />
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PDPFulfillment
