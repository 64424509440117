import { useState, useContext } from 'react'
import Context from '../context'
import getConfig from 'next/config'
import Link from 'next/link'
import Image from 'next/image'

import StarRating from './StarRating'
import { useRouter } from 'next/router'
import AddToCart from './AddToCart'

import QuickView from '../components/QuickView'
import { useIonModal } from '@ionic/react'
import { thumbLoader } from '../utils/custom-image-loader'
import SVG from './SVG'

const { publicRuntimeConfig } = getConfig()

const ProductCard = ({ data, vertical = false, forceVertical = false, number = null }) => {
  const { state } = useContext(Context)
  const router = useRouter()
  const inStoreOnly = data.in_store_only === 'true' || data.in_store_only === true
  const brokenImage = '/images/popshelf-broken-onwhite.svg'

  const imageUrlProcessor = url => {
    if (url?.includes('scene7')) {
      return url
    }
    const ext = url?.split('.').pop()
    return `${url}/_jcr_content/renditions/${publicRuntimeConfig.DAM_NAME}.web.200.200.${ext === 'jpg' ? 'jpeg' : ext}`
  }

  const [imageSrc, setImageSrc] = useState(
    imageUrlProcessor(data.image_urls?.split(',')[0]) || brokenImage
  )

  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(QuickView, {
    handleDismiss: handleDismiss,
    data: data
  })

  const getQuantity = () => {
    return state.cart?.products?.find((product) => +data.upc === +product.upc)?.quantity || 0
  }

  const setButtons = () => {
    if (data.variants?.length) {
      return (
        <button
          className="btn__secondary"
          key={data.upc}
          onClick={(e) => {
            e.preventDefault()
            router.push(`/p/${data.slug}`)
          }}
        >
          View Options
        </button>
      )
    } else if (data.in_stock && !inStoreOnly && parseFloat(data?.price || 0)?.toFixed(2) !== '0.01' && parseFloat(data?.final_price || 0)?.toFixed(2) !== '0.01') {
      return <AddToCart product={data} cartQuantity={getQuantity()} />
    } else {
      return (
        <button
          className="btn__secondary"
          key={data.upc}
          onClick={(e) => {
            e.preventDefault()
            router.push(`/p/${data.slug}`)
          }}
        >
          View details
        </button>
      )
    }
  }

  const getProductAvailability = () => {
    if (inStoreOnly) {
      return (
        <div className="product-card__availability__text">
          Available in-store
          <p className="product-card__availability-fulfillment-greencheck__mobile-text"> only</p>
        </div>
      )
    } else {
      return (
        <div className="product-card__availability__text">
          <p className="product-card__availability-fulfillment-greencheck__mobile-text">Available for</p> Pickup
        </div>
      )
    }
  }
  const stockText = data.inventory ? (data.inventory > 5 ? `${data.inventory} in stock` : 'Low stock') : 'Out of stock'

  return (
    <div className="product-card__container">
      <Link href="/p/[slug]" as={`/p/${data.slug}`} scroll={true}>
        <a
          s_objectid={`${data.digital_product_name || data.product_name} | ${data.sku || 'N/A'} | ${data.campaign_name || null
            } | ${data.date_reference || null} `}
        >
          <div
            data-testid="product-card-link-inner"
            className={`product-card${vertical ? ' product-card__vertical' : ''} ${forceVertical ? 'product-card__force-vertical' : ''
              }`}
          >
            <div className="product-card__details">
              {number && <div className="product-card__details-number">{number}</div>}
              <div className="product-card__details-image">
                <Image
                  src={imageSrc}
                  alt={data.digital_product_name || data.product_name}
                  layout="fill"
                  loader={thumbLoader}
                  onError={() => setImageSrc(brokenImage)}
                />
                {!forceVertical && (
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      present({
                        cssClass: 'quickview-modal'
                      })
                    }}
                  >
                    Quick View
                  </button>
                )}
              </div>

              <div className="product-card__details-desc">
                <p className="product-card__details-name">{data.digital_product_name || data.product_name}</p>
                <div className="product-card__details-metadata">
                  <div>
                    <div className="product-card__details-rating">
                      <StarRating percent={data.avg_rating} id={data.upc} />
                      <p>({data.rating_count})</p>
                    </div>

                    <div className="product-card__details-variants">
                      <p hidden={!data.variants?.length}>{data.variants?.length} choices</p>
                    </div>
                  </div>
                  <div className="product-card__details-price">
                    <div className="product-card__details-price-default">
                      <p hidden={isNaN(data.price) || data.price === 0}>{`$${parseFloat(
                        data.discount_price ? data.discount_price : data.price
                      )?.toFixed(2)}`}</p>
                    </div>
                    <p className="product-card__details-price-discount" hidden={!data.discount_price}>{`reg $${parseFloat(
                      data.price
                    )?.toFixed(2)}`}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card__availability">
              <div className="product-card__availability-fulfillment">
                {publicRuntimeConfig.SHOW_OOS_TEXT === 'true' && (
                  <p className={data.inventory < 5 || !data.inventory ? 'oos' : ''}>
                    {stockText} <span className={data.inventory < 5 || !data.inventory ? 'oos' : ''}>at your store</span>
                  </p>
                )}
                <div className="product-card__availability-fulfillment-greencheck">
                  <SVG name="checkmark-green" label="checkmark-green" size="base" />
                  {getProductAvailability()}
                </div>
              </div>
              <div className="product-card__availability__setbutton">{setButtons()}</div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default ProductCard
