import React from 'react'
import SVG from './SVG'

const VariantPicker = ({ data, onSelect, name, value, quickView = false }) => {
  return (
    <div data-testid={'variant-picker-container'} className={`variant-picker ${quickView && 'quickview__options-container'}`}>
      <div data-testid={'variant-picker-title'} className={`variant-picker__text ${quickView && 'quickview__options-title'}`}>
        <p>{name}</p>
      </div>
      <div data-testid={'variant-picker-dropdown'} className={` ${quickView ? 'quickview__options-dropdown' : 'variant-picker__dropdown'}`}>
        <div className="variant-picker__dropdown__container">
          <select data-testid={'select'} id="colors" name="colors" onChange={(e) => onSelect(e.target.value)}>
            <option disabled selected value={''}>-- Select a {name} --</option>
            {data.sort().map(item => (<option data-testid={'select-option'} value={item.value ? item.value : item.number} key={item.number} selected={value === item.number}>{item.number}</option>))}
          </select>
          <SVG name='carrot-down' label='carrot-down' size='base' />
        </div>
      </div>
    </div>
  )
}

export default VariantPicker
